.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.right-arrow {
  position: fixed;
  cursor: pointer;
}

.no-products {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  p {
    cursor: pointer;
    color: #28728f;
    font-style: italic;
    text-decoration: underline;
  }
}

.table-spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.table-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #28728f;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
